"use client";

import React, { useMemo, useState } from "react";
import { AppContextType } from "./types";
import { AppContext } from "./AppContext";
import { useGoogleCategoryProvider } from "@/src/hooks/useGoogleCategories";
import { useLocaleCountriesProvider } from "@/src/hooks/useCountryOptions";
import { useCurrenciesProvider } from "@/src/hooks/useCurrencyOptions";
import { useCustomCategoryProvider } from "@/src/hooks/useCustomCategory";
export interface AppProviderProps {
  appContext: AppContextType;
  children: React.ReactNode;
}
export type AppWindowType = typeof window & {
  __ENV__: AppContextType;
  clarity?: (action: string, data: string, session?: string, page?: string, hint?: string) => void;
};
const ClientCache: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  useGoogleCategoryProvider();
  useLocaleCountriesProvider();
  useCurrenciesProvider();
  useCustomCategoryProvider();
  return children;
};
export const AppProvider: React.FC<AppProviderProps> = ({
  appContext,
  children
}: AppProviderProps) => {
  const [ctx, setContext] = useState<AppContextType>(appContext);
  const contextVal = useMemo(() => ({
    ctx,
    setContext
  }), [ctx.sideMenuCollapsed, ctx.customCategory, ctx.googleCategory, ctx.currencies, ctx.countries]);
  return <AppContext.Provider value={contextVal} data-sentry-element="unknown" data-sentry-component="AppProvider" data-sentry-source-file="index.tsx">
      <ClientCache data-sentry-element="ClientCache" data-sentry-source-file="index.tsx">{children}</ClientCache>
    </AppContext.Provider>;
};